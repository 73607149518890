<template>
  <main>
    <section class="skv-academy__banner">
      <div class="skv-academy__banner-top">
        <div class="content">
          <div class="skv-academy__banner-container">
            <div class="skv-academy__banner-text-container">
              <div class="skv-academy__banner-title">Академия СКВ</div>
              <div class="skv-academy__banner-description">
                Меняя взгляд на волчанку
                <br /><br />
                Научно-информационный проект, посвященный современным подходам
                к ведению пациентов с системной красной волчанкой
              </div>
            </div>
            <div class="skv-academy__banner-links">
              <div class="skv-academy__banner-links_row">
                <a
                  href="#video-1"
                  @click.prevent="scrollTo('#video-1')"
                  class="skv-academy__banner-links_item"
                >
                  <div class="skv-academy__banner-links_title">Смотреть</div>
                  <div class="skv-academy__banner-links_link">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_156_1903)">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="#EBEFEE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14 9.33948L20 15.3395L14 21.3395"
                          fill="#830051"
                        />
                        <path
                          d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                          stroke="#830051"
                          stroke-width="2"
                          stroke-linecap="square"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_156_1903">
                          <rect
                            y="0.339478"
                            width="30"
                            height="30"
                            rx="15"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Модуль 1</span>
                  </div>
                </a>
                <a
                  href="#video-2"
                  @click.prevent="scrollTo('#video-2')"
                  class="skv-academy__banner-links_item"
                >
                  <div class="skv-academy__banner-links_title">Смотреть</div>
                  <div class="skv-academy__banner-links_link">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_156_1903)">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="#EBEFEE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14 9.33948L20 15.3395L14 21.3395"
                          fill="#830051"
                        />
                        <path
                          d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                          stroke="#830051"
                          stroke-width="2"
                          stroke-linecap="square"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_156_1903">
                          <rect
                            y="0.339478"
                            width="30"
                            height="30"
                            rx="15"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Модуль 2</span>
                  </div>
                </a>
                <a
                  href="#video-3"
                  @click.prevent="scrollTo('#video-3')"
                  class="skv-academy__banner-links_item"
                >
                  <div class="skv-academy__banner-links_title">Смотреть</div>
                  <div class="skv-academy__banner-links_link">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_156_1903)">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="#EBEFEE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14 9.33948L20 15.3395L14 21.3395"
                          fill="#830051"
                        />
                        <path
                          d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                          stroke="#830051"
                          stroke-width="2"
                          stroke-linecap="square"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_156_1903">
                          <rect
                            y="0.339478"
                            width="30"
                            height="30"
                            rx="15"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Модуль 3</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="skv-academy__banner-image_container">
          <picture
            ><source
              :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/skv(mob).png
              `"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/skv(tab).png
              `"
              media="(max-width: 1220px)"
            />
            <source
              :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/skv.png
              `"
              media="(max-width: 1919px)"
            />
            <img
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/skv.png`"
              class="skv-academy__banner-image"
            />
          </picture>
        </div>
        <div class="skv-academy__banner-links_mob">
          <div class="content">
            <div class="skv-academy__banner-links_row">
              <a
                href="#video-1"
                @click.prevent="scrollTo('#video-1')"
                class="skv-academy__banner-links_item"
              >
                <div class="skv-academy__banner-links_title">Смотреть</div>
                <div class="skv-academy__banner-links_link">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_156_1903)">
                      <rect
                        y="0.339478"
                        width="30"
                        height="30"
                        rx="15"
                        fill="#EBEFEE"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 9.33948L20 15.3395L14 21.3395"
                        fill="#830051"
                      />
                      <path
                        d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                        stroke="#830051"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_156_1903">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Модуль 1</span>
                </div>
              </a>
              <a
                href="#video-2"
                @click.prevent="scrollTo('#video-2')"
                class="skv-academy__banner-links_item"
              >
                <div class="skv-academy__banner-links_title">Смотреть</div>
                <div class="skv-academy__banner-links_link">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_156_1903)">
                      <rect
                        y="0.339478"
                        width="30"
                        height="30"
                        rx="15"
                        fill="#EBEFEE"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 9.33948L20 15.3395L14 21.3395"
                        fill="#830051"
                      />
                      <path
                        d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                        stroke="#830051"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_156_1903">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Модуль 2</span>
                </div>
              </a>
                <a
                href="#video-3"
                @click.prevent="scrollTo('#video-3')"
                class="skv-academy__banner-links_item"
              >
                <div class="skv-academy__banner-links_title">Смотреть</div>
                <div class="skv-academy__banner-links_link">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_156_1903)">
                      <rect
                        y="0.339478"
                        width="30"
                        height="30"
                        rx="15"
                        fill="#EBEFEE"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 9.33948L20 15.3395L14 21.3395"
                        fill="#830051"
                      />
                      <path
                        d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                        stroke="#830051"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_156_1903">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Модуль 3</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="skv-academy__info">
      <div class="content">
        <div class="skv-academy__info-container">
          <div class="skv-academy__info-head">
            <div class="skv-academy__info-title">3 модуля</div>
            <div class="skv-academy__info-text">
              с записями вебинаров цикла «Академия СКВ»
            </div>
          </div>
          <div class="skv-academy__info-line"></div>
          <div class="skv-academy__info-body">
            Ведущие ревматологи России ответят на основные вопросы, связанные с
            ведением пациентов с СКВ — от ранней диагностики до современной
            терапии:
            <ul class="skv-academy__info-body_list">
              <li>Каковы современные критерии постановки диагноза СКВ?</li>
              <li>
                Алгоритмы выбора терапии согласно стратегии «Лечения до
                достижения цели».
              </li>
              <li>
                Особенности назначения ГИБП с практическим разбором клинических
                случаев.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="skv-academy__speakers">
      <div class="content">
        <div class="skv-academy__speakers-title">Спикеры «Академии СКВ»</div>
        <div class="skv-academy__speakers-row">
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-1.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-tag">Модератор:</div>
              <div class="skv-academy__speakers-name">
                Насонов Евгений Львович
              </div>
              <div class="skv-academy__speakers-job">
                академик РАН, научный руководитель ФГБНУ НИИР им. В.А. Насоновой
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-2.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-tag">Модератор:</div>
              <div class="skv-academy__speakers-name">
                Лила Александр Михайлович
              </div>
              <div class="skv-academy__speakers-job">
                д.м.н., профессор, заслуженный врач РФ, член-корреспондент РАН,
                главный внештатный специалист-ревматолог МЗ РФ, директор ФГБНУ
                НИИР им. В.А. Насоновой
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-3.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-name">
                Соловьев Сергей Константинович
              </div>
              <div class="skv-academy__speakers-job">
                профессор, д.м.н., руководитель Центра инновационной
                ревматологии, главный врач-ревматолог МЕДСИ
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-4.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-name">
                Попкова Татьяна Валентиновна
              </div>
              <div class="skv-academy__speakers-job">
                д.м.н., заведующая лабораторией системных ревматических
                заболеваний ФГБНУ НИИР им. В.А. Насоновой
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-5.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-name">
                Панафидина Татьяна Александровна
              </div>
              <div class="skv-academy__speakers-job">
                к.м.н., старший научный сотрудник лаборатории системных
                ревматических заболеваний ФГБНУ НИИР им. В.А. Насоновой
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-6.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-name">
                Решетняк Татьяна Магомедалиевна
              </div>
              <div class="skv-academy__speakers-job">
                д.м.н., заведующая лабораторией тромбовоспаления ФГБНУ НИИР им.
                В.А. Насоновой
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-7.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-name">
                Асеева Елена Александровна
              </div>
              <div class="skv-academy__speakers-job">
                д.м.н., ведущий научный сотрудник лаборатории системной красной
                волчанки ФГБНУ НИИР им. В.А. Насоновой
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="skv-academy__video">
      <div class="content">
        <div class="skv-academy__video-first">
          <div class="skv-academy__video-head">
            <div class="skv-academy__video-head_mod">Модуль 1.</div>
            <div class="skv-academy__video-head_text">
              СКВ — стратегия и тактика. Современные взгляды на диагностику
              и классификационные критерии СКВ
            </div>
          </div>
          <div class="skv-academy__video-player">
            <InfoDrop
              class="skv-academy__video-player_drop"
              :active="true"
              @openInner="openInner"
              :item="{
                title: 'Программа',
                content: FirstContent.description,
                content_above: FirstContent.video,
              }"
            />
          </div>
        </div>
        <div class="skv-academy__video-second">
          <div class="skv-academy__video-head">
            <div class="skv-academy__video-head_mod">Модуль 2.</div>
            <div class="skv-academy__video-head_text">
              Особенности терапии пациентов с СКВ. Вопросы стандартной терапии
              и бремени длительного применения глюкокортикостероидов,
              возможности и особенности применения ГИБП с практическим разбором
              клинических случаев
            </div>
          </div>
          <div class="skv-academy__video-player">
            <InfoDrop
              class="skv-academy__video-player_drop"
              @openInner="openInner"
              :active="true"
              :item="{
                title: 'Программа',
                content: SecondContent.description,
                content_above: SecondContent.video,
              }"
            />
          </div>
        </div>
        <div class="skv-academy__video-third">
          <div class="skv-academy__video-head">
            <div class="skv-academy__video-head_mod">Модуль 3.</div>
            <div class="skv-academy__video-head_text">
              Рекомендации EULAR 2023 по СКВ. Реальная клиническая практика Сафнело® (анифролумаба)
            </div>
          </div>
          <div class="skv-academy__video-player">
            <InfoDrop
              class="skv-academy__video-player_drop"
              @openInner="openInner"
              :active="true"
              :item="{
                title: 'Программа',
                content: ThirdContent.description,
                content_above: ThirdContent.video,
              }"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="skv-academy__btn" v-if="false">
      <div class="content">
        <div class="skv-academy__subscribe button button_pink px-4">
          Подписаться на уведомления о новых записях
        </div>
      </div>
    </section>
    <section class="skv-academy__abbs">
      <div class="content">
        СКВ – системная красная волчанка;
        <br /><br />
        ФГБОУ ВО – Федеральное государственное бюджетное образовательное
        учреждение высшего образования;
        <br /><br />
        РОО – региональная общественная организация;
        <br /><br />
        МЗ РФ – Министерство здравоохранения Российской Федерации;
        <br /><br />
        РАН – Российская академия наук;
        <br /><br />
        ГИБП – генно-инженерный биологический препарат;
        <br /><br />
        ФГБНУ НИИР – Федеральное государственное бюджетное научное учреждение
        "Научно-исследовательский институт ревматологии";
        <br /><br />
        ГКС – глюкокортикостероиды.
      </div>
    </section>
  </main>
</template>

<script>
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
export default {
  name: "SkvAcademy",
  components: {
    InfoDrop,
  },
  data: (vm) => ({
    items: [],
    index: null,
  }),
  computed: {
    loadedRefs() {
      return this.$refs;
    },
    FirstContent() {
      return {
        id: 1,
        content: [
          {
            active: true,
          },
        ],
        video: `<video
            controls
            class="skv-video"
            id="video-1"
            src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/Академия-СКВ_часть-1-min.mp4"
            poster="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/video-1.jpg"
          ></video>`,
        description: `
<div class="skv-academy timecodes">
  <div class="timecodes__body">
    <div class="timecodes__row">
      <div class="timecodes__theme">Проблемы и вызовы СКВ</div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Попкова Татьяна Валентиновна</div>
          <div class="timecodes__time">00:07:08</div>
        </div>
      </div>
    </div>
    <div class="timecodes__row">
      <div class="timecodes__theme">Стратегия лечения до достижения цели при СКВ</div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Соловьев Сергей Константинович</div>
          <div class="timecodes__time">00:22:20</div>
        </div>
      </div>
    </div>
    <div class="timecodes__row">
      <div class="timecodes__theme">Классификационные критерии СКВ и мониторинг пациентов на амбулаторном этапе</div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Решетняк Татьяна Магомедалиевна</div>
          <div class="timecodes__time">00:44:00</div>
        </div>
      </div>
    </div>
    <div class="timecodes__row">
      <div class="timecodes__theme">Неполная СКВ: ранняя диагностика и подходы к ведению пациентов</div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Панафидина Татьяна Александровна</div>
          <div class="timecodes__time">01:04:11</div>
        </div>
      </div>
    </div>
  </div>
</div>`,
      };
    },
    SecondContent() {
      return {
        id: 2,
        content: [
          {
            active: true,
          },
        ],
        video: `<video
            controls
            class="skv-video"
            id="video-2"
            src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/Академия-СКВ_часть-2-min.mp4"
            poster="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/video-2.jpg"
          ></video>`,
        description: `
<div class="skv-academy timecodes">
  <div class="timecodes__body">
    <div class="timecodes__row">
      <div class="timecodes__theme">Современные алгоритмы применения стандартной терапии СКВ</div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Попкова Татьяна Валентиновна</div>
          <div class="timecodes__time">00:07:28</div>
        </div>
      </div>
    </div>
    <div class="timecodes__row">
      <div class="timecodes__theme">Глюкокортикоиды в лечении СКВ: аргументы за и против?</div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Асеева Елена Александровна</div>
          <div class="timecodes__time">00:22:55</div>
        </div>
      </div>
    </div>
    <div class="timecodes__row">
      <div class="timecodes__theme">Место ГИБП в терапии пациентов с СКВ</div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Решетняк Татьяна Магомедалиевна</div>
          <div class="timecodes__time">00:39:38</div>
        </div>
      </div>
    </div>
  </div>
</div>`,
      };
    },
       ThirdContent() {
      return {
        id: 3,
        content: [
          {
            active: true,
          },
        ],
        video: `<video
            controls
            class="skv-video"
            id="video-3"
            src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/module3.mp4"
            poster="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/video-3.jpg"
          ></video>`,
        description: `
<div class="skv-academy timecodes">
  <div class="timecodes__body">
    <div class="timecodes__row">
      <div class="timecodes__theme">Вступительное слово председателя</div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Председатель: Насонов Евгений Львович, д.м.н., профессор, академик РАН, президент Ассоциации ревматологов России</div>
          <div class="timecodes__time">00:00:25</div>
        </div>
      </div>
    </div>
    <div class="timecodes__row">
      <div class="timecodes__theme">Что нового в терапии СКВ в 2023 году</div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Попкова Татьяна Валентиновна, д.м.н., заведующая лабораторией системных ревматических заболеваний ФГБНУ НИИР им. В.А. Насоновой</div>
          <div class="timecodes__time">00:04:34</div>
        </div>
      </div>
    </div>
    <div class="timecodes__row">
      <div class="timecodes__theme">Критерии диагностики и цели терапии СКВ</div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Решетняк Татьяна Мегомедалиевна, д.м.н., заведующая лабораторией тромбовоспаления ФГБНУ НИИР им. В.А. Насоновой</div>
          <div class="timecodes__time">00:29:45</div>
        </div>
      </div>
    </div>
    <div class="timecodes__row">
      <div class="timecodes__theme">Локальный опыт применения ингибитора ИНФ типа I у пациентов с СКВ</div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Асеева Елена Александровна, д.м.н., ведущий научный сотрудник лаборатории системной красной волчанки ФГБНУ НИИР им. В.А. Насоновой</div>
          <div class="timecodes__time">00:50:54</div>
        </div>
      </div>
    </div>
    <div class="timecodes__row">
      <div class="timecodes__theme">Пациент с разнообразными клиническими проявлениями СКВ </div>
      <div>
        <div class="timecodes__lectors-row">
          <div class="timecodes__lector">Асеева Елена Александровна, д.м.н., ведущий научный сотрудник лаборатории системной красной волчанки ФГБНУ НИИР им. В.А. Насоновой</div>
          <div class="timecodes__time">01:05:17</div>
        </div>
      </div>
    </div>
  </div>
</div>`,
      };
    },
  },
  methods: {
    scrollTo(selector) {
      const element = document.querySelector(selector);
      const y = element.getBoundingClientRect().top + window.scrollY;
      if (element) {
        window.scrollTo({
          behavior: "smooth",
          top: y - 300,
        });
      }
    },
    openInner() {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "see broadcast programm click", {
          "see broadcast programm click": {
            ...this.$root.ymFields,
          },
        });
      }
    },
  },
  async mounted() {},
};
</script>

<style lang="scss" scoped>
section {
  margin-top: 90px;
  @media screen and(max-width: 1199px) {
    margin-top: 40px;
  }
}
.skv-academy {
  &__banner {
    margin-top: 0;
    &-top {
      position: relative;
      height: 668px;
      @media screen and(max-width: 1199px) {
        height: 400px;
      }
      @media screen and(max-width: 767px) {
        height: auto;
      }
      .content {
        height: 100%;
      }
    }
    &-container {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 172px 0 40px;
      @media screen and(max-width: 1199px) {
        padding: 40px 0 55px;
      }
       @media screen and(max-width: 767px) {
        padding: 24px 0;
       }
    }
    &-text-container {
      max-width: 592px;
      color: #ffffff;
      @media screen and(max-width: 1199px) {
        max-width: 325px;
      }
      @media screen and(max-width: 767px) {
        max-width: none;
        width: 100%;
        color: #1f1f1f;
      }
    }
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 42px;
      line-height: 48px;
      font-weight: 400;
    }
    &-description {
      margin-top: 12px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 21px;
      line-height: 26px;
      font-weight: 400;
      @media screen and(max-width: 1199px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    &-links {
      margin-top: auto;
      &_row {
        display: flex;
        flex-direction: row;
        gap: 16px;
        @media screen and(max-width: 767px) {
          margin-top: 24px;
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
      &_item {
        width: 180px;
        display: flex;
        flex-direction: column;
        padding: 10px 10px 20px;
        background-color: #ffffff;
        border-radius: 5px;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
         @media screen and(max-width: 1199px) {
          width: 150px;
         }
         @media screen and(max-width: 767px) {
          max-width: 163px;
          width: 100%;
        }
      }
      &_link {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        color: #830051;
        span {
          margin-left: 8px;
        }
      }
      &_mob {
        display: none;
        @media screen and(max-width: 767px) {
          display: block;
        }
      }
      @media screen and(max-width: 767px) {
        display: none;
      }
    }
    &-image_container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @media screen and(max-width: 767px) {
        height: 220px;
        position: static;
      }
    }
    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__info {
    &-container {
      padding: 48px 40px;
      background-color: #ebefee;
    }
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media screen and(max-width: 1199px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-title {
      margin-right: 36px;
      display: flex;
      flex-direction: row;
      font-family: "Roboto Slab", sans-serif;
      font-size: 42px;
      font-weight: 400;
      line-height: 48px;
      color: #830051;
    }
    &-text {
      padding-top: 5px;
      font-family: "Roboto", sans-serif;
      font-size: 23px;
      font-weight: 500;
      line-height: 29px;
      color: #3c4242;
    }
    &-line {
      margin: 34px 0;
      border-bottom: 1px solid #ffff;
    }
    &-body {
      max-width: 75%;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      color: #3c4242;
      @media screen and(max-width: 1199px) {
        max-width: none;
      }
      &_list {
        margin-top: 16px;
        list-style: disc;
        padding-left: 25px;
        font-weight: 500;
      }
    }
  }
  &__speakers {
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-weight: 400;
      line-height: 29px;
    }
    &-row {
      margin-top: 56px;
      padding-bottom: 64px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px 102px;
      @media screen and(max-width: 1199px) {
        margin-top: 40px;
        grid-template-columns: 1fr;
        padding-bottom: 0;
      }
    }
    &-item {
      width: 100%;
      display: grid;
      grid-template-columns: 140px 1fr;
      grid-gap: 24px;
      @media screen and(max-width: 767px) {
        grid-template-columns: 80px 1fr;
      }
    }
    &-img {
      height: 140px;
      width: 140px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      @media screen and(max-width: 767px) {
        width: 80px;
        height: 80px;
      }
    }
    &-info {
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and(max-width: 1199px) {
        height: auto;
      }
    }
    &-tag {
      margin-bottom: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      @media screen and(max-width: 767px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
    &-name {
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      @media screen and(max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    &-job {
      margin-top: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      @media screen and(max-width: 767px) {
        margin-top: 8px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  &__video {
    &-second {
      margin-top: 40px;
    }
    &-head {
      margin-bottom: -40px;
      padding: 28px 48px;
      display: flex;
      flex-direction: row;
      background-color: #0d2943;
      color: #ffffff;
      @media screen and(max-width: 1199px) {
        flex-direction: column;
        padding: 24px 32px;
      }
      @media screen and(max-width: 767px) {
        padding: 24px 16px;
      }
      &_mod {
        display: flex;
        width: 130px;
        margin-right: 40px;
        font-family: "Roboto", sans-serif;
        font-size: 23px;
        line-height: 29px;
        font-weight: 500;
        @media screen and(max-width: 1199px) {
          margin-right: 0;
          margin-bottom: 24px;
        }
        @media screen and(max-width: 767px) {
          font-size: 18px;
          line-height: 22px;
        }
      }
      &_text {
        width: fit-content;
        font-family: "Roboto Slab", sans-serif;
        font-size: 23px;
        font-weight: 400;
        line-height: 29px;
        @media screen and(max-width: 767px) {
          font-size: 21px;
          line-height: 26px;
        }
      }
    }
    &-player {
      // background: #ebefee;
      &_drop {
        .info-item {
          padding: 20px;
        }
      }
    }
  }
  &__subscribe {
    max-width: fit-content;
    @media screen and(max-width: 767px) {
      max-width: 100%;
      width: 100%;
    }
  }
  &__abbs {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1f1f1f;
  }
}
</style>
<style lang="scss">
.skv-video {
  width: 100%;
  height: auto;
}
.skv-academy.timecodes {
  margin-top: 0;
  // background-color: #ebefee;
  .timecodes__row {
    grid-template-columns: 40% calc(60% - 32px);
    @media screen and(max-width: 767px) {
      grid-template-columns: 1fr;
      // grid-template-rows: repeat(2, 1fr);
    }
    .timecodes__lectors-row {
      grid-template-columns: 1fr auto 20px;
      @media screen and(max-width: 1199px) {
        grid-template-columns: 1fr auto;
      }
      .timecodes__time {
        padding: 5px 10px;
        border-radius: 99px;
        background-color: #fff;
        line-height: 26px;
        color: #1f1f1f;
        // text-decoration: none;
      }
    }
  }
}
</style>
